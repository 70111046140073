import { FC, lazy, Suspense, SuspenseProps } from "react";
import { LoadingFullscreen } from "./Component/Container";

const Pages = {
  Apartment: {
    Home: lazy(async () => {
      const { Home } = await import("./Apartment");
      return {
        default: Home,
      };
    }),
    ManagerBill: lazy(async () => {
      const { ManageBill } = await import("./Apartment");
      return {
        default: ManageBill,
      };
    }),
    ManageRoom: lazy(async () => {
      const { ManageRoom } = await import("./Apartment");
      return {
        default: ManageRoom,
      };
    }),
    ManageMember: lazy(async () => {
      const { ManageMember: _default } = await import("./Apartment");
      return {
        default: _default,
      };
    }),
    UserRegister: lazy(async () => {
      const { UserRegister: _default } = await import("./Apartment");
      return {
        default: _default,
      };
    }),
    ManageDiscount: lazy(async () => {
      const { ManageDiscount: _default } = await import("./Apartment");
      return {
        default: _default,
      };
    }),
    ManageProblem: lazy(async () => {
      const { ManageProblem: _default } = await import("./Apartment");
      return {
        default: _default,
      };
    }),
    ManageProduct: lazy(async () => {
      const { ManageProduct: _default } = await import("./Apartment");
      return {
        default: _default,
      };
    }),
    PrintSummaryReport: lazy(async () => {
      const { PrintSummaryReport: _default } = await import("./Apartment");
      return {
        default: _default,
      };
    }),
  },
  Manager: {
    Home: lazy(async () => {
      const { Home: _default } = await import("./Manager");
      return {
        default: _default,
      };
    }),
    ManageStaff: lazy(async () => {
      const { ManageStaff: _default } = await import("./Manager");
      return {
        default: _default,
      };
    }),
    PrintSummaryReportApartment: lazy(async () => {
      const { PrintSummaryReportApartment: _default } = await import("./Manager");
      return {
        default: _default,
      };
    }),
    PrintSummaryReportCoWorking: lazy(async () => {
      const { PrintSummaryReportCoWorking: _default } = await import("./Manager");
      return {
        default: _default,
      };
    }),
    PrintSummaryReportStaff: lazy(async () => {
      const { PrintSummaryReportStaff: _default } = await import("./Manager");
      return {
        default: _default,
      };
    }),
  },
  CoWorking: {
    Home: lazy(async () => {
      const { Home: _default } = await import("./Co-Working");
      return {
        default: _default,
      };
    }),
    ManageRoom: lazy(async () => {
      const { ManageRoom: _default } = await import("./Co-Working");
      return {
        default: _default,
      };
    }),
    ManageBooking: lazy(async () => {
      const { ManageBooking: _default } = await import("./Co-Working");
      return {
        default: _default,
      };
    }),
    ManageMember: lazy(async () => {
      const { ManageMember: _default } = await import("./Co-Working");
      return {
        default: _default,
      };
    }),
    RegisterUser: lazy(async () => {
      const { RegisterUser: _default } = await import("./Co-Working");
      return {
        default: _default,
      };
    }),
    ManageDiscount: lazy(async () => {
      const { ManageDiscount: _default } = await import("./Co-Working");
      return {
        default: _default,
      };
    }),
    ManageProduct: lazy(async () => {
      const { ManageProduct: _default } = await import("./Co-Working");
      return {
        default: _default,
      };
    }),
    ManageBill: lazy(async () => {
      const { ManageBill: _default } = await import("./Co-Working");
      return {
        default: _default,
      };
    }),
    PrintSummaryReport: lazy(async () => {
      const { PrintSummaryReport: _default } = await import("./Co-Working");
      return {
        default: _default,
      };
    }),
  },
};

export const LazyPageWrapper: FC<{ children: SuspenseProps["children"] }> = ({ children }) => {
  return <Suspense fallback={<LoadingFullscreen />}>{children}</Suspense>;
};
export default Pages;
