//import logo from './logo.svg';
import "./App.css";
import { Navbar } from "./Component/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { LoadingFullscreen } from "./Component/Container";
import { NotFound } from "./Component/NotFound";
import { Login } from "./Login";
import { Footer } from "./Component/Footer";
// import * as Apartment from "./Apartment";
// import * as CoWorking from "./Co-Working";
// import * as Manager from "./Manager";
import { Toaster } from "react-hot-toast";
import UserContext from "./Apartment/contexts/userContext";

import { AdminAPI } from "./Apartment/api/service";
import { CustomType } from "shared-lib/src/types/api/User";
import Pages, { LazyPageWrapper } from "./Pages";
import * as SocketIOClient from "socket.io-client";
//import { faCheckSquare, faCoffee, IconName } from "@fortawesome/free-solid-svg-icons";

type User = "CO-WORKING" | "APARTMENT" | "GUEST" | "LOADING" | "MANAGER";

const notFoundEl = <Route key="not-found" path="*" element={<NotFound />} />;

const RoutesList = (props: { status?: User }) => {
  if (props.status === "GUEST") {
    return [<Route key="login" path="*" element={<Login />} />];
  }
  if (props.status === "APARTMENT") {
    return [
      notFoundEl,
      <Route
        key="home"
        path="/"
        element={
          <LazyPageWrapper>
            <Pages.Apartment.Home />
          </LazyPageWrapper>
        }
      />,
      <Route key="a" path="/a" element={<>Home A</>} />,
      <Route
        key="manage-room"
        path="/manage-room"
        element={
          <LazyPageWrapper>
            <Pages.Apartment.ManageRoom />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="manage-member"
        path="/manage-member"
        element={
          <LazyPageWrapper>
            <Pages.Apartment.ManageMember />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="product"
        path="/product"
        element={
          <LazyPageWrapper>
            <Pages.Apartment.ManageProduct />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="manage-bill"
        path="/manage-bill"
        element={
          <LazyPageWrapper>
            <Pages.Apartment.ManagerBill />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="manage-problem"
        path="/manage-problem"
        element={
          <LazyPageWrapper>
            <Pages.Apartment.ManageProblem />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="manage-discount"
        path="/manage-discount"
        element={
          <LazyPageWrapper>
            <Pages.Apartment.ManageDiscount />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="summary-report"
        path="/summary-report"
        element={
          <LazyPageWrapper>
            <Pages.Apartment.PrintSummaryReport />
          </LazyPageWrapper>
        }
      />,
      // <Route
      //   key="manage-user/register"
      //   path="/manage-user/register"
      //   element={<Apartment.UserRegister />}
      // />,
    ];
  }
  if (props.status === "CO-WORKING") {
    return [
      notFoundEl,
      <Route
        key="home"
        path="/"
        element={
          <LazyPageWrapper>
            <Pages.CoWorking.Home />
          </LazyPageWrapper>
        }
      />,
      <Route key="b" path="/b" element={<>Home B</>} />,
      <Route
        key="manage-room"
        path="/manage-room"
        element={
          <LazyPageWrapper>
            <Pages.CoWorking.ManageRoom />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="manage-booking"
        path="/manage-booking"
        element={
          <LazyPageWrapper>
            <Pages.CoWorking.ManageBooking />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="manage-member"
        path="/manage-member"
        element={
          <LazyPageWrapper>
            <Pages.CoWorking.ManageMember />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="manage-discount"
        path="/manage-discount"
        element={
          <LazyPageWrapper>
            <Pages.CoWorking.ManageDiscount />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="product"
        path="/product"
        element={
          <LazyPageWrapper>
            <Pages.CoWorking.ManageProduct />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="manage-bill"
        path="/manage-bill"
        element={
          <LazyPageWrapper>
            <Pages.CoWorking.ManageBill />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="print-summary"
        path="/print-summary"
        element={
          <LazyPageWrapper>
            <Pages.CoWorking.PrintSummaryReport />
          </LazyPageWrapper>
        }
      />,
    ];
  }
  if (props.status === "MANAGER") {
    return [
      notFoundEl,
      <Route
        key="home"
        path="/"
        element={
          <LazyPageWrapper>
            <Pages.Manager.Home />
          </LazyPageWrapper>
        }
      />,
      <Route key="b" path="/b" element={<>Home C</>} />,
      <Route
        key="manage-staff"
        path="/manage-staff"
        element={
          <LazyPageWrapper>
            <Pages.Manager.ManageStaff />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="print-summary-report-apartment"
        path="/print-summary-report-apartment"
        element={
          <LazyPageWrapper>
            <Pages.Manager.PrintSummaryReportApartment />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="print-summary-report-co-working"
        path="/print-summary-report-co-working"
        element={
          <LazyPageWrapper>
            <Pages.Manager.PrintSummaryReportCoWorking />
          </LazyPageWrapper>
        }
      />,
      <Route
        key="print-summary-report-staff"
        path="/print-summary-report-staff"
        element={
          <LazyPageWrapper>
            <Pages.Manager.PrintSummaryReportStaff />
          </LazyPageWrapper>
        }
      />,
    ];
  }

  return [<Route key="loading" path="*" element={<LoadingFullscreen />} />];
};

// const useRoutesApp = () => {
//   const [status, setStatus] = useState<User>("LOADING");
//   const [routes, setRoutes] = useState<JSX.Element[]>(RoutesList({}));

//   useEffect(() => {
// window.setTimeout(() => {
//   setStatus("GUEST");
// }, 1000);
//   }, []);

//   useEffect(() => {
//     setRoutes(RoutesList({ status: status }));
//   }, [status]);

//   return {
//     routes,
//     status,
//   };
// };

const initUserDataState: CustomType.Info = {
  id: "",
  active: false,
  username: "",
  firstName: "",
  lastName: "",
  birthDate: 0,
  email: "",
  phoneNumber: "",
  lineId: "",
  department: "apartment",
  role: "staff",
  memberExpiryDate: 0,
  isApartmentMember: false,
  // staffId: "",
  bankAccountNo: "",
  bankAccountName: "",
  bank: "",
  salary: 0,
  position: "",
  userNo: "",
  startWorkingDate: 0,
  endWorkingDate: 0,
};

const App = () => {
  // const { routes, status } = useRoutesApp();
  //const navigate = useNavigate();
  const [auth, setAuth] = useState(false);
  const [status, setStatus] = useState<User>("LOADING");
  const [routes, setRoutes] = useState<JSX.Element[]>(RoutesList({}));
  const [userData, setUserData] = useState<CustomType.Info>();
  const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null);

  useEffect(() => {
    const socket = SocketIOClient.io(import.meta.env.VITE_APP_API_DOMAIN, {
      transports: ["polling", "websocket"],
      withCredentials: true,
    });
    setSocket(socket);
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const UserResponse = await AdminAPI.User.get_own();
        if (typeof UserResponse.data.data !== "undefined") {
          setUserData(UserResponse.data.data);
        }
      } catch (e) {
        setUserData(initUserDataState);
      }
    })();
  }, [auth]);

  useEffect(() => {
    window.setTimeout(() => {
      if (
        userData?.id !== "" &&
        userData?.role === "staff" &&
        userData?.department === "apartment"
      ) {
        setStatus("APARTMENT");
        setAuth(true);
      } else if (
        userData?.id !== "" &&
        userData?.role === "staff" &&
        userData?.department === "co-working"
      ) {
        setStatus("CO-WORKING");
        setAuth(true);
      } else if (
        userData?.id !== "" &&
        userData?.role === "manager" &&
        userData?.department === "manager"
      ) {
        setStatus("MANAGER");
      } else if (typeof userData === "undefined") {
        setStatus("LOADING");
      } else {
        setStatus("GUEST");
      }
    }, 1000);
  }, [userData]);

  useEffect(() => {
    if (status === "GUEST" && window.location.pathname !== "/") {
      window.location.href = "/";
    }
    setRoutes(RoutesList({ status: status }));
  }, [status]);

  return (
    <>
      <Toaster position="top-right" />
      <div>
        <UserContext.Provider value={{ auth, setAuth }}>
          <div className="App">
            <BrowserRouter>
              <Navbar status={status} socket={socket} />
              <Routes>{routes}</Routes>
            </BrowserRouter>
          </div>
        </UserContext.Provider>
        <Footer />
      </div>
    </>
  );
};

export default App;
export type { User };
