import * as API from "../../api";
import * as Types from "../../main";
// import * as
// import { Modify } from "../../";

export const subPath = "/apartment";

export namespace Room {
  export const subPath = "/room";

  export namespace CustomType {
    export type GetResponseData = {
      billId: string | null;
      renter: API.User.CustomType.Basic | null;
      dateIn: number | null;
      dateOut: number | null;
      bookingStatus: Types.BookingStatus.Def | null;
    };
  }

  /**
   * ✅
   * Get rooms for room management
   *
   * - path: /staff/apartment/room/get-all
   * - method: GET
   * @ for apartment staff
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = API.Pagination & {
      /** roomNo */
      search?: string;
      type?: Types.Apartment.Room.RentType.Def;
      // /** cover all day */
      // date?: number;
      // startDate?: number;
      // endDate?: number;
    };
    export type Response = API.ResponsePayload<
      (Omit<Types.Apartment.Room.Type, "renter"> & CustomType.GetResponseData)[]
    >;
  }

  /**
   * Get a available apartment rooms (daily).
   *
   * - path: /staff/apartment/room/get-available
   * - method: "GET"
   * @ for for apartment staff.
   */
  export namespace GetAvailableRoom {
    export const subPath = "/get-available";
    /** querystring */
    export type Request = {
      startDate: number;
      endDate: number;
      roomType: Types.Apartment.Room.RoomType.Def;
    };
    export type Response = API.ResponsePayload<Pick<Types.Apartment.Room.Type, "roomNo" | "id">[]>;
  }

  /**
   * ✅
   * Get room for room management
   *
   * - path: /staff/apartment/room/get-by-id
   * - method: GET
   * @ for apartment staff
   */
  export namespace GetById {
    export const subPath = "/get-by-id";
    /** querystring */
    export type Request = {
      id: string;
    };
    export type Response = API.ResponsePayload<
      Omit<Types.Apartment.Room.Type, "renter"> & CustomType.GetResponseData
    >;
  }

  // /**
  //  * Get room for room management
  //  *
  //  * - path: /staff/apartment/room/get-by-id
  //  * - method: GET
  //  * @ for apartment staff
  //  */
  // export namespace Get

  /**
   * ✅
   * Set (add) renter to target room.
   * Can only set to an available room.
   *
   * - path: /staff/apartment/room/set-renter
   * - method: PUT
   * @ for apartment staff
   */
  export namespace SetRenter {
    export const subPath = "/set-renter";

    // internally creating a bill.
    /** body */
    export type Request = {
      userId: string;
      roomId: string;
      startDate: number;
      endDate: number;
      firstPaid: number | null;
      code: string | null;
      paymentMethod: Types.Bill.PaymentMethod.Def;
    };
    export type Response = API.ResponsePayload<null, "set renter to target room successfully.">;
  }

  /**
   * ✅
   * Remove current renter from target room.
   *
   * - path: /staff/apartment/room/remove-renter
   * - method: PUT
   * @ for apartment staff
   */
  export namespace RemoveRenter {
    export const subPath = "/remove-renter";
    /** body */
    export type Request = {
      // userId: string;
      roomId: string;
    };
    export type Response = API.ResponsePayload<
      null,
      "remove renter from target room successfully."
    >;
  }

  /**
   * ✅
   * Edit room info.
   *
   * - path: /staff/apartment/room/edit
   * - method: PUT
   * @ for apartment staff
   */
  export namespace Edit {
    export const subPath = "/edit";
    /** body */
    export type Request = { roomId: string } & Partial<
      Pick<
        Types.Apartment.Room.Type,
        "rentType" | "dailyPrice" | "monthlyPrice" | "weekendPrice" | "roomSize" | "roomType"
      >
    >;
    export type Response = API.ResponsePayload<null, "edit the info of target room successfully.">;
  }
}

export namespace Booking {
  export const subPath = "/booking";

  export type CustomResponse = Omit<Types.Apartment.Booking, "userId" | "roomId"> & {
    renter: API.User.CustomType.Basic | Types.Guest.Type;
    room: Pick<Types.Apartment.Room.Type, "id" | "roomNo" | "roomType">;
    transactionImg: string | null;
    paidDate: number | null;
  };

  /**
   * ✅
   * Get room-bookings for calendar view (daily)
   *
   * - path: /staff/apartment/booking/get-calendar-view
   * - method: GET
   * @ for apartment staff
   */
  export namespace GetCalendarView {
    export const subPath = "/get-calendar-view";
    /** querystring */
    export type Request = {
      month: Types.Month;
      year: number;
      // day: number;
    };
    export type Response = API.ResponsePayload<CustomResponse[]>;
  }

  /**
   * Get a booking.
   *
   * - path: /staff/apartment/booking/get-by-id
   * - method: "GET"
   *  @ for apartment staff
   */
  export namespace GetById {
    export const subPath = "/get-by-id";
    export type Request = {
      id: string;
    };
    export type Response = API.ResponsePayload<CustomResponse>;
  }

  /**
   * ✅
   * Get room-bookings of selected room.
   *
   * - path: /staff/apartment/booking/get-all-by-room-id
   * - method: GET
   * @ for apartment staff
   */
  export namespace GetAllByRoomId {
    export const subPath = "/get-all-by-room-id";
    /** querystring */
    export type Request = API.Pagination & {
      roomId: string;
      sinceDate?: number;
      endDate?: number;
    };
    export type Response = API.ResponsePayload<CustomResponse[]>;
  }

  // /**
  //  * Edit the booking.
  //  *
  //  * - path: /staff/apartment/booking/edit
  //  * - method: PUT
  //  * @ for apartment staff
  //  */
  // export namespace EditBooking {
  //   export type Request = {
  //     userId: string;
  //     roomId: string;
  //     // dateIn?: number;
  //     // dateOut?: number;
  //     status?: Type.BookingStatus.Def;
  //   };
  //   export type Response = API.ResponsePayload<{/**/}>;
  // }

  /**
   * ✅
   * Confirm booking.
   *
   * - path: /staff/apartment/booking/confirm-booking
   * - method: PUT
   * @ for apartment staff
   */
  export namespace ConfirmBooking {
    export const subPath = "/confirm-booking";
    /** body */
    export type Request = {
      bookingId: string;
      roomId: string;
    };
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }

  /**
   * ✅
   * Set the booking status.
   *
   * - path: /staff/apartment/booking/set-status
   * - method: PUT
   * @ for apartment staff
   */
  export namespace SetBookingStatus {
    export const subPath = "/set-status";
    /** body */
    export type Request = {
      bookingId: string;
      // userId: string;
      // roomId: string;
      // dateIn?: number;
      // dateOut?: number;
      status: Exclude<Types.BookingStatus.Def, "waiting" | "confirmed">;
    };
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }
}

export namespace Bill {
  export const subPath = "/bill";

  export type CustomBill = Omit<Types.Bill.Type<"apartment">, "userId" | "seller" | "confirmor"> & {
    user: API.User.CustomType.Basic;
    seller: API.User.CustomType.Basic;
    confirmor: API.User.CustomType.Basic | null;
  };

  /**
   * ✅
   * Get the all bill info.
   *
   * - path: /staff/apartment/bill/get-all
   * - method: GET
   * @ for apartment staff
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = API.Pagination & {
      /** billNo, roomNo */
      search?: string;
      startDate: number;
    };
    export type Response = API.ResponsePayload<CustomBill[]>;
  }

  /**
   * ✅
   * Get the bill info of monthly.
   *
   * - path: /staff/apartment/bill/get-monthly
   * - method: POST
   * @ for apartment staff
   */
  export namespace GetMonthly {
    export const subPath = "/get-monthly";
    /** body */
    export type Request = {
      // bookingId: string;
      // renter: string;
      roomId: string;
      monthYear: Types.MonthYear;
    };
    export type Response = API.ResponsePayload<CustomBill>;
  }

  /*
   * ✅
   * Calculate room price.
   *
   * - path: /staff/apartment/bill/calculate-room-price
   * - method: GET
   * @ for apartment staff
   */
  export namespace CalculateRoomPrice {
    export const subPath = "/calculate-room-price";
    /** querystring */
    export type Request = {
      roomId: string;
      /** rejected from calculation in case of monthly room */
      startDate: number;
      /** rejected from calculation in case of monthly room */
      endDate: number;
    };
    export type Response = API.ResponsePayload<{ amount: number }>;
  }

  export type AllowBillType = Exclude<Types.Bill.BillType.Def, "co-working">;

  /**
   * ✅
   * Create the new bill.
   *
   * - path: /staff/apartment/bill/create
   * - method: POST
   * @ for apartment staff
   */
  export namespace Create {
    export const subPath = "/create";
    /** body */
    export type Request = {
      userId: string;
      expiryDate: number | null;
      // type: AllowBillType;
      purchasedProducts: Omit<
        Types.Bill.PurchasedProduct,
        "department" | "total" | "subTotal" | "createdTime"
      >[];
      method: Types.Bill.PaymentMethod.Def;
      monthYear?: Types.MonthYear;
      paid: boolean;
      // overwrite: boolean;
      room: {
        id: string;
        startDate: number;
        endDate: number;
      } | null;
      unit: Omit<Types.Bill.UtilityHistory, "roomId" | "total" | "subTotal" | "createdTime"> | null;
      code: string | null;
      firstPaid: number | null;
      type: Types.Bill.Type<"apartment">["type"];
      monthlyEdit?: boolean;
    };
    // & (BillType extends "room-rental"
    //   ? {
    //       roomId: string;
    //       // bookings: Omit<Type.Apartment.Booking, "userId" | "roomId">[];
    //       /** current monthly bill */
    //       unit: Omit<Types.Bill.UtilityHistory, "roomId">;
    //     }
    //   : {
    //       /**/
    //     });
    export type Response = API.ResponsePayload<{
      /**/

      newBillNo: string;
    }>;
  }

  // /**
  //  * Remove the bill
  //  *
  //  * - path: /staff/apartment/bill/remove
  //  * - method: DELETE
  //  * @ for apartment staff
  //  */
  // export namespace Remove {
  //   export const subPath = "/remove";
  //   /** body */
  //   export type Request = {
  //     billId: string;
  //   };
  //   export type Response = API.ResponsePayload<{
  //     /**/
  //   }>;
  // }

  /**
   * ✅
   * Set the bill to expired or paid.
   * also auto set all bookings to "confirmed"
   *
   * - path: /staff/apartment/bill/set-status
   * - method: PUT
   * @ for apartment staff
   */
  export namespace SetStatus {
    export const subPath = "/set-status";

    export type AllowStatus = Exclude<Types.Bill.Status.Def, "unpaid">;

    export type Request = {
      billId: string;
      status: AllowStatus;
    };

    export type Response = API.ResponsePayload<Request, "">;
  }

  /**
   * ✅
   * Get the image of transaction bill
   *
   * - path: /staff/apartment/bill/get-transaction-img-url
   * - method: GET
   * @ for apartment staff
   */
  export namespace GetTransactionImgUrl {
    export const subPath = "/get-transaction-img-url";

    export type Request = {
      /** querystring */
      imgPath: string;
    };

    export type Response = API.ResponsePayload<{ imgUrl: string }>;
  }

  export namespace UploadImage {
    export const subPath = "/upload";

    export type Request = {
      /** */
      file: any;
    };

    export type Response = API.ResponsePayload<{ path: string }>;
  }
}

export namespace IssueReport {
  export const subPath = "/issue-report";
  /**
   * ✅
   * Get all issue reports from customer
   *
   * - path: /staff/apartment/issue-reports/get-all
   * - method: GET
   * @ for apartment staff
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = API.Pagination & {
      // name?: string;
      // username?: string;
      // roomNo?: string;
      /**
       * roomNo
       */
      search?: string;
    };
    export type Response = API.ResponsePayload<
      (Types.Apartment.IssueReport & { roomNo: string })[]
    >;
  }
}

export namespace Notification {
  export const subPath = "/notification";

  /**
   *
   * Get all apartment daily booking notifications.
   *
   * - path: /staff/apartment/notification/get-all
   * - method: GET
   * @ for apartment staff
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = {
      /** */
    };
    export type Response = API.ResponsePayload<
      Types.Notification.Type[],
      "get all notifications successfully"
    >;
  }

  /**
   *
   * Set notification is read.
   *
   * - path: /staff/apartment/notification/set-is-read
   * - method: PUT
   * @ for apartment staff
   */
  export namespace SetIsRead {
    export const subPath = "/set-is-read";
    /** body */
    export type Request = {
      notiId: string;
      isRead: boolean;
    };
    export type Response = API.ResponsePayload<Request, "set status of notification successfully">;
  }
}

export namespace Product {
  export const subPath = "/product";

  /**
   * ✅
   * Get additional apartment products/services.
   *
   * - path: /staff/apartment/products/get-all
   * - method: GET
   * @ for apartment staff
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = API.Pagination & {
      search?: string;
    };
    export type Response = API.ResponsePayload<
      Types.Product.Type[],
      "get all product successfully"
    >;
  }

  /**
   * ✅
   * Add additional apartment product/service.
   *
   * - path: /staff/apartment/product/add-one
   * - method: POST
   * @ for apartment staff
   */
  export namespace AddOne {
    export const subPath = "/add-one";
    /** body */
    export type Request = Omit<Types.Product.Type, "id" | "department">;
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }

  /**
   * ✅
   * Edit an additional apartment product/service.
   *
   * - path: /staff/apartment/product/edit
   * - method: PUT
   * @ for apartment staff
   */
  export namespace Edit {
    export const subPath = "/edit";
    /** body */
    export type Request = { id: string } & Partial<Omit<Types.Product.Type, "id" | "department">>;
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }

  // /**
  //  * Active additional apartment products/services.
  //  *
  //  * - path: /staff/apartment/product/add-one
  //  * - method: PUT
  //  * @ for apartment staff
  //  */
  // export namespace SetActive {
  //   export type Request = { id: string };
  //   export type Response = API.ResponsePayload<{/**/}>;
  // }

  // /**
  //  * Un-active additional apartment products/services.
  //  *
  //  * - path: /staff/apartment/product/add-one
  //  * - method: PUT
  //  * @ for apartment staff
  //  */
  // export namespace SetNotActive {
  //   export type Request = { id: string };
  //   export type Response = API.ResponsePayload<{/**/}>;
  // }

  /**
   * ✅
   * Remove additional apartment products/services.
   *
   * - path: /staff/apartment/product/remove
   * - method: DELETE
   * @ for apartment staff
   */
  export namespace Remove {
    export const subPath = "/remove";
    /** querystring */
    export type Request = { id: string };
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }
}

export namespace Discount {
  export const subPath = "/discount";

  /**
   * ✅
   * Add apartment discount.
   *
   * - path: /staff/apartment/discount/add
   * - method: POST
   * @ for apartment staff
   */
  export namespace Add {
    export const subPath = "/add";
    /** body */
    export type Request = Omit<Types.Discount.Type, "id" | "department">;
    export type Response = API.ResponsePayload<{
      /**/
    }>;
  }

  /**
   * ✅
   * Get apartment discounts info.
   *
   * - path: /staff/apartment/discount/get-all
   * - method: GET
   * @ for apartment staff
   */
  export namespace GetAll {
    export const subPath = "/get-all";
    /** querystring */
    export type Request = API.Pagination & {
      search?: string;
    };
    export type Response = API.ResponsePayload<
      Types.Discount.Type[],
      "get all discount successfully"
    >;
  }

  /**
   * ✅
   * Get apartment discounts info.
   *
   * - path: /staff/apartment/discount/get-one
   * - method: GET
   * @ for apartment staff
   */
  export namespace GetOne {
    export const subPath = "/get-one";
    /** querystring */
    export type Request = { id: string };
    export type Response = API.ResponsePayload<Types.Discount.Type>;
  }

  // /**
  //  * Get apartment discounts info by code.
  //  *
  //  * - path: /staff/apartment/discount/search
  //  * - method: GET
  //  * @ for apartment staff
  //  */
  // export namespace Search {
  //   export type Request = { code: string };
  //   export type Response = API.ResponsePayload<Type.Discount.Type>;
  // }

  /**
   * ✅
   * Edit apartment discount info.
   *
   * - path: /staff/apartment/discount/edit
   * - method: PUT
   * @ for apartment staff
   */
  export namespace Edit {
    export const subPath = "/edit";
    /** body */
    export type Request = {
      id: string;
      // department: Types.Department.Def;
      // type: Types.Discount.Discounting.Def;
    } & Partial<Omit<Types.Discount.Type, "id" | "department">>;
    export type Response = API.ResponsePayload<{
      //
    }>;
  }

  /**
   * ✅
   * Remove apartment discount.
   *
   * - path: /staff/apartment/discount/remove
   * - method: DELETE
   * @ for apartment staff
   */
  export namespace Remove {
    export const subPath = "/remove";
    /** querystring */
    export type Request = { id: string };
    export type Response = API.ResponsePayload<{
      //
    }>;
  }

  /**
   * ✅
   * Try apply a discount code.
   *
   * - path: /staff/apartment/discount/try-apply
   * - method: POST
   * @ for apartment staff
   */
  export namespace TryApply {
    export const subPath = "/try-apply";

    export type RequestForDiscountingItem = {
      /** for convenient front-end item name debugging.
       *
       * can be
       * - name of items.
       * - auto generated id.
       */
      name: string;
      amount: number;
    };

    /** old */
    // export type CustomBill = {
    //   booking: Pick<
    //     Types.Apartment.Booking,
    //     "roomId" | "type" | "dateIn" | "dateOut" | "firstPaid"
    //   >;
    //   products: (Pick<Types.Product.Type, "id" | "price"> & {
    //     count: number;
    //   })[];
    //   utilityHistories: Omit<Types.Bill.UtilityHistory, "roomId">[];
    // };

    // only calculate current month rent.
    // /** querystring */
    // export type Request = { code: string; bill: CustomBill };
    // export type Response = API.ResponsePayload<{
    //   booking: {
    //     firstPaid: number;
    //     rentPrice: number;
    //   };
    //   products: CustomBill["products"];
    //   subTotal: number;
    //   total: number;
    // }>;

    export type Request = {
      code: string;
      items: RequestForDiscountingItem[];
    };

    export type Response = API.ResponsePayload<{
      items: (RequestForDiscountingItem & { discounted: number })[];
      name: string;
    }>;
  }
}

export namespace Member {
  export const subPath = "/member";

  // /**
  //  * Register a new member
  //  *
  //  * - path: /staff/apartment/member/register-customer
  //  * - method: "POST"
  //  * @ for apartment staff
  //  */
  // export namespace RegisterMember {
  //   export const subPath = "/register-memberr";
  //   /** body */
  //   export type Request = Pick<
  //     Types.User.Type,
  //     "firstName" | "lastName" | "email" | "phoneNumber" | "lineId"
  //   >;
  //   export type Response = API.ResponsePayload<
  //     null,
  //     | "registered a new member user successfully"
  //     | {
  //         error: API.User.Register.ErrorMessage;
  //       }
  //   >;
  // }

  /**
   * ✅
   * Get all members
   *
   * - path: /staff/apartment/member/get-all-members
   * - method: "GET"
   *  @ for apartment staff
   */
  export namespace GetAllMembers {
    export const subPath = "/get-all-members";
    /** querystring */
    export type Request = API.Pagination & {
      search?: string;
      isApartmentMember?: boolean;
      active?: boolean;
    };
    export type Response = API.ResponsePayload<
      (Pick<
        Types.User.Type,
        "id" | "firstName" | "lastName" | "email" | "phoneNumber" | "lineId" | "active" | "userNo"
      > & {
        createdTime: number;
      })[]
    >;
  }

  // /**
  //  * Get member from Search (use for regis room modal and member modal)
  //  *
  //  * - path: /staff/apartment/member/get-one-member
  //  * - method: "GET"
  //  *  @ for apartment staff
  //  */
  // export namespace GetSearchedMember {
  //   export const subPath = "/get-searched-member";
  //   /** querystring */
  //   export type Request = {
  //     search?: string;
  //   };
  //   export type Response = API.ResponsePayload<
  //     Pick<Types.User.Type, "firstName" | "lastName" | "email" | "phoneNumber" | "lineId">[]
  //   >;
  // }

  /**
   * ✅
   * Edit a member
   *
   * - path: /staff/apartment/member/edit
   * - method: "PUT"
   *  @ for apartment staff
   */
  export namespace EditMember {
    export type DefaultData = Pick<Types.User.Type, "id"> &
      Partial<Pick<Types.User.Type, "firstName" | "lastName" | "phoneNumber" | "email" | "lineId">>;
    //
    export const subPath = "/edit";
    /** body */
    export type Request = DefaultData;
    export type Response = API.ResponsePayload<null, "edit member info successfully">;
  }

  /**
   * ✅
   * Set status of user (active or inactive)
   *
   * - path: /staff/apartment/member/set-status
   * - method: "PUT"
   * @ for apartment staff
   */
  export namespace SetStatus {
    //
    export const subPath = "/set-status";
    export const method = "put";
    /** body */
    export type Request = {
      id: string;
      active: boolean;
    };
    export type Response = API.ResponsePayload<Request, "set member status successfully">;
  }
}

export namespace Report {
  export const subPath = "/report";

  export type BaseReportRequest = {
    dateIn: number;
    dateOut: number;
  };

  export type TotalPrice = {
    subTotal: number;
    totalDiscount: number;
    total: number;
  };

  /**
   * - amount = calculated.
   */
  export type Discount = Pick<Types.Discount.Type, "code" | "amount"> | null;

  export namespace Apartment {
    /** querystring */
    export type Request = BaseReportRequest & {
      roomType: Types.Apartment.Room.RentType.Def;
      water: boolean;
      roomRental: boolean;
      electric: boolean;
      goods: boolean;
    };

    export type SoldSummaryItem = {
      timestamp: number;
      roomName: string | null;
      billType: Types.Bill.BillType.Def;
      amount: number | null;
      price: number;
      total: number;
      discount: Discount;
    };

    export type SeparatedRoomExpense = {
      roomNo: string;
      rentPrice: number;
      electricPrice: number;
      waterPrice: number;
      discount: Discount;
      rentPaid: boolean;
      total: number;
    };

    export type RoomRental = {
      roomNo: string;
      price: number;
      discount: Discount;
      overdue: boolean;
      total: number;
    };

    export type Electric = {
      roomNo: string;
      amount: number;
      price: number;
      discount: Discount;
      overdue: boolean;
      total: number;
    };

    export type Water = Electric;

    export type Goods = {
      roomNo: string;
      name: string;
      amount: number;
      price: number;
      total: number;
    };

    export type Summary = {
      amount: number;
      // totalCount: number | null;
      paidCount: number | null;
      overdueCount: number | null;
      paid: number;
      overdue: number;
      expected: number;
    };

    export type Response = API.ResponsePayload<{
      sold: {
        items: SoldSummaryItem[];
      } & TotalPrice;
      seperatedRoomExpense: {
        items: SeparatedRoomExpense[];
      } & TotalPrice;
      expense: {
        roomRental: {
          items: RoomRental[];
        } & TotalPrice;
        electric: {
          items: Electric[];
        } & TotalPrice;
        water: {
          items: Water[];
        } & TotalPrice;
        goods: {
          items: Goods[];
        } & TotalPrice;
      };
      summary: {
        rental?: Summary;
        water?: Summary;
        electric?: Summary;
        goods?: Summary;
        discount: Summary;
      } & TotalPrice;
    }>;
  }
}

// export namespace Bill {
//   export namespace GetMonthlyBill {

//   }
// }
