import { css, cx } from "@emotion/css";
import { COLOR } from "../styles";

const Style = css({
  "&:checked": {
    backgroundColor: `${COLOR.yellow} !important`,
    borderColor: `${COLOR.yellow} !important`,
  },
  "&:focus": {
    appearance: "none",
    outline: "none !important",
    boxShadow: "none !important",
  },
});

const Checkbox = (props: {
  label: string;
  value: boolean;
  disabled?: boolean;
  onChange: () => void;
  center?: boolean;
  dayList?: boolean;
  summary?: boolean;
  product?: boolean;
  productId?: string;
}) => {
  const { label, value, onChange, center, dayList, summary, product, productId, disabled } = props;
  let billLabel = "";
  if (summary) {
    switch (label) {
      case "water":
        billLabel = "ค่าน้ำ";
        break;
      case "electric":
        billLabel = "ค่าไฟ";
        break;
      case "roomRental":
        billLabel = "ค่าห้องพัก";
        break;
      case "goods":
        billLabel = "สินค้าเพิ่มเติม";
        break;
    }
  }
  return (
    <div className={"form-check"}>
      <input
        className={cx("form-check-input", Style)}
        type="checkbox"
        id={product ? productId : ""}
        checked={value}
        onChange={onChange}
        disabled={disabled ? disabled : false}
      />
      <label
        className={`form-check-label ${
          center || dayList || summary ? "justify-content-center" : ""
        }`}
      >
        {summary ? billLabel : label}
      </label>
    </div>
  );
};

export { Checkbox };
